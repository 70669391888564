<template>
  <div class="login">
    <div class="login__content">
      <div class="logo">
        <img :src="require(`@/assets/images/TGHLogoWordmarkBlue.png`)" alt="logo" />
      </div>
      <ValidationObserver v-slot="{ invalid }" tag="section" class="welcome form">
        <h1>Welcome back</h1>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, email: true }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Enter your email"
              :class="{ 'form-control--invalid': error || errors[0] }"
              :validateError="errors[0]"
              v-model="user.username"
              :element="user.username"
              @input="user.username = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true }"
            v-slot="{ errors }"
          >
            <PasswordInput
              placeholder="Enter your password"
              type="password"
              name="password"
              :validateError="error || errors[0]"
              v-model="user.password"
              :element="user.password"
              @input="user.password = $event"
            ></PasswordInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <div class="form__row_col form__row_col--side">
            <BaseCheckbox
              label="Remember me"
              :checked="Boolean(user.remember_me)"
              @input="user.remember_me = +$event"
            ></BaseCheckbox>
          </div>
          <div class="form__row_col form__row_col--side form__row_col--justify-end">
            <BaseButton
              text="Forgot password"
              class="button--only-text"
              @click="forgotPass.show = true"
            ></BaseButton>
          </div>
        </div>
        <div class="form__row">
          <BaseButton
            text="Login"
            class="login__button button--black button--uppercase"
            :disabled="invalid"
            :loading="loader"
            @click="login"
          ></BaseButton>
        </div>
      </ValidationObserver>
      <div class="footer">
        <p>© Collekt 2022</p>
        <ul>
          <li><router-link to="/terms-and-conditions">Terms & Conditions</router-link></li>
          <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
        </ul>
      </div>
    </div>
    <div class="login__image"></div>
    <PopUp
      class="modal--xxs"
      :show="forgotPass.show"
      bgColor="rgba(0,0,0,0.8)"
      :close="false"
      @hideModal="cancel()"
    >
      <ValidationObserver v-slot="{ invalid }" tag="section" class="forgot-pass welcome form">
        <p>Send password reset email</p>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, email: true }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Enter your email"
              class="form-control--sm"
              :class="{ 'form-control--invalid': forgotPass.error || errors[0] }"
              :validateError="forgotPass.error || errors[0]"
              v-model="forgotPass.email"
              :element="forgotPass.email"
              @input="forgotPass.email = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <div class="form__row_col form__row_col--side form__row_col--justify-end">
            <BaseButton
              text="Cancel"
              class="button--xs button--outline-grey button--uppercase"
              @click="cancel"
            ></BaseButton>
          </div>
          <div class="form__row_col form__row_col--side">
            <BaseButton
              text="Send"
              class="button--xs button--black button--uppercase"
              :disabled="invalid"
              :loading="forgotPass.loader"
              @click="send"
            ></BaseButton>
          </div>
        </div>
      </ValidationObserver>
    </PopUp>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BaseInput from "@/components/inputs/BaseInput.vue";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseCheckbox from "@/components/inputs/BaseCheckbox.vue";
import PopUp from "@/components/PopUp.vue";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BaseInput,
    PasswordInput,
    BaseButton,
    BaseCheckbox,
    PopUp,
  },
  data() {
    return {
      user: {
        username: "",
        password: "",
        remember_me: "",
      },
      forgotPass: {
        show: false,
        email: "",
        error: "",
        loader: false,
      },
      error: "",
    };
  },
  computed: {
    ...mapGetters("auth", { loader: "getLoading" }),
  },
  methods: {
    ...mapActions("auth", ["loginUser", "forgotPassword"]),
    async login() {
      let result = await this.loginUser(this.user);
      this.error = result.error;
    },
    async send() {
      this.forgotPass.loader = true;
      const data = {
        email: this.forgotPass.email,
      };
      let result = await this.forgotPassword(data);
      if (result?.error) this.forgotPass.error = result.error;
      else this.forgotPass.show = false;
      this.forgotPass.loader = false;
    },
    cancel() {
      this.forgotPass.email = "";
      this.forgotPass.error = "";
      this.forgotPass.loader = false;
      this.forgotPass.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  display: flex;
  height: 100vh;
  padding: 15px 15px;
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 42%;
    height: 100%;
    margin-right: 5rem;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      width: 100%;
      margin-right: 0;
    }
    .logo {
      width: 200px;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        width: 100px;
        margin: 0 auto;
      }
    }
    .welcome {
      width: 100%;
      padding: 4.5rem 11rem;
      @media only screen and (max-width: $lg) {
        padding: 20px 5rem;
      }
      @media only screen and (max-width: $md) {
        padding: 20px 0;
      }
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        max-width: 50rem;
        margin: 0 auto;
        padding: 10px 0 20px;
      }
      h1 {
        font-size: 4.4rem;
        font-weight: 500;
        line-height: 1.34;
        margin-bottom: 4.8rem;
        @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
          font-size: 2.5rem;
          margin-bottom: 3rem;
          text-align: center;
        }
      }
      .login__button {
        margin-top: 1.3rem;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0 10px 25px;
      @media only screen and (max-width: $md) {
        padding: 10px 0;
        flex-direction: column-reverse;
        align-items: flex-start;
      }
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        align-items: center;
      }
      p {
        @include type();
        @media only screen and (max-width: $md) {
          margin: 15px auto 0 0;
        }
        @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
          font-size: 14px;
          margin: 15px auto 0;
        }
      }
      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width: $md) {
          justify-content: flex-end;
        }
        li {
          a {
            text-decoration: none;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.16;
            padding: 0 15px;
            color: $lightBlack;
            cursor: pointer;
            @media only screen and (max-width: $md) {
              padding: 0 15px 0 0;
            }
            @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
              padding: 0 15px;
            }
            &:hover {
              color: $blue;
            }
          }
        }
      }
    }
  }
  &__image {
    width: 58%;
    max-width: 105rem;
    margin: 0 0 0 auto;
    height: 100%;
    border-radius: 14px;
    background-image: url("~@/assets/images/login.png");
    background-position: center center;
    background-size: cover;
    @media only screen and (max-width: $md) {
      max-height: 500px;
      margin: auto;
    }
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      display: none;
    }
  }
}
.forgot-pass {
  p {
    text-align: center;
    @include type($fs: 2.6rem, $ls: -1px);
    margin-bottom: 4rem;
  }
}
</style>
