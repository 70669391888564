<template>
  <label class="checkbox">
    <input type="checkbox" :checked="checked" :disabled="disabled" v-model="input" />
    <span class="checkbox__custom">
      <IconCheck></IconCheck>
    </span>
    {{ label }}
  </label>
</template>
<script>
import IconCheck from "@/components/icons/IconCheck.vue";
export default {
  components: { IconCheck },
  props: {
    label: String,
    checked: {
      type: Boolean,
      default: false,
    },
    id: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    input: false,
  }),
  created() {
    this.input = this.checked;
  },
  watch: {
    checked(val) {
      this.input = val;
    },
    input(val) {
      this.$emit("input", val);
      this.$emit("select", { value: val, id: this.id });
    },
  },
};
</script>
<style lang="scss" scoped>
.checkbox {
  display: inline-flex;
  align-items: center;
  @include type();
  color: $lightGrey;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
    font-size: 14px;
  }
  &__custom {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    border-radius: 5px;
    border: 1px solid $lightGrey;
    margin-right: 8px;
  }
  input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
  }
  input:checked ~ &__custom {
    background: $blue;
    border-color: $blue;
    &::after {
      margin-right: 0px;
    }
  }
}
</style>