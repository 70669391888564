<template>
  <svg :width="width" :height="height" :viewBox="viewBox" fill="none">
    <g :fill="color">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.49911 10.7799L4.18578 8.46655C3.92578 8.20655 3.50578 8.20655 3.24578 8.46655C2.98578 8.72655 2.98578 9.14655 3.24578 9.40655L6.03245 12.1932C6.29245 12.4532 6.71245 12.4532 6.97245 12.1932L14.0258 5.13988C14.2858 4.87988 14.2858 4.45988 14.0258 4.19988C13.7658 3.93988 13.3458 3.93988 13.0858 4.19988L6.49911 10.7799Z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 17 16" },
    width: { type: String, default: "17" },
    height: { type: String, default: "16" },
    color: { type: String, default: "white" },
  },
};
</script>